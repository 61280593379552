import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { catchError, forkJoin, of } from 'rxjs';
import { environment as env } from "../../../environments/environment";
import * as d3 from 'd3';
import { ActivatedRoute } from '@angular/router';
import { FilterService, FilterSettingsModel, GridComponent, PageSettingsModel, ResizeService, SortService, SortSettingsModel, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { SliderTickRenderedEventArgs } from '@syncfusion/ej2-angular-inputs';
import { ClipboardService } from 'ngx-clipboard';
declare var $:any;

const DEFAULT_REGION = "";

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers:[ResizeService, ToolbarService, SortService, FilterService]
})
export class ReportComponent implements OnInit, AfterViewInit {

  @ViewChild('grid') 
  public grid: GridComponent;

  @ViewChild('template') 
  public vendorColTemplate: any; 

  optInNodes:any;
  optOutNodes:any;

  optInData:any;
  optOutData:any;
  defaultData:any;

  url:string;
  uuid:string;
  detailsHeader:string;
  detailsSubheader:string;
  detailsDict:any;
  detailsData:any;
  selectedDetail:string;

  hasTranscend:boolean = null;
  hasOneTrust:boolean = null;
  hasCookieBot:boolean = null;
  hasPrivici:boolean = null;
  hasKetch:boolean = null;
  hasTrustArc:boolean = null;
  hasTruyo:boolean = null;
  hasEvidon:boolean = null;
  hasCookieYes:boolean = null;
  hasOsano:boolean = null;
  hasTealium:boolean = null;

  hasCustom:boolean = null;
  

  customCMP:string = null;
  optInContainerId = "optInRequestGraphContainer";
  optOutContainerId = "optInRequestGraphContainer";
  optInZoom:any;
  optOutZoom:any;
  mainDuration = 0;

  optInMaxGroupedNodes = 0;
  optOutMaxGroupedNodes = 0;

  selectedType = "Opt In"

  public type: string = 'MinRange';
  public slider_ticks: Object = { placement: 'Both', largeStep: 100, format: ''};


  public pageSettings:PageSettingsModel;
  public sortSettings:SortSettingsModel;
  public toolbar: string[];
  public filterOptions: FilterSettingsModel = { type: 'Excel' };

  iconBaseUrl = env.iconBaseUrl;

  optInVendorTotal:number = null;
  optOutVendorTotal:number = null;

  inSvg:any;
  outSvg:any;

  public value: number = 0;
  public showButtons: boolean = false;
  public tooltip: Object = {
      placement: 'Before',
      isVisible: true,
      showOn: 'Focus'
  };

  optInOpacity = .5
  optOutOpacity = .5

  timestamp:string = null;
  region:string = null;

  AVAILABLE_REGIONS;
  
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private clipboard:ClipboardService) 
  {           
    this.pageSettings = {
      currentPage: 1, 
      pageSize: 50, 
      pageCount: 4, 
      pageSizes: [50, 75, 100, 125]
    };
    this.toolbar = ['Search'];
  }

  ngAfterViewInit(): void {
    this.detailsDict  = {
      "total": {
        label: "All Requests", 
        arrayName: 'tpRequests', 
        columns:[ {headerText: 'Vendor', width: "150px", template: this.vendorColTemplate, field:'vendorName', type:'string'}, {field: 'file', headerText: 'Request URL', type:'string'}],
        sortSettings: {columns: [{ field: 'vendorName', direction: 'Ascending'}] }
      },
      "data": {
        label: "Data Requests", 
        arrayName: 'tpDataRequests',
        columns:[ {headerText: 'Vendor', width: "150px", template: this.vendorColTemplate, field:'vendorName', type:'string'}, {field: 'file', headerText: 'Request URL', type:'string'}],
        sortSettings: {columns: [{ field: 'vendorName', direction: 'Ascending'}] }
      },
      "js":{
        label: "JavaScript Requests", 
        arrayName: 'tpJavaScriptRequests',
        columns:[ {headerText: 'Vendor', width: "150px", template: this.vendorColTemplate, field:'vendorName', type:'string'}, {field: 'file', headerText: 'Request URL', type:'string'}],
        sortSettings: {columns: [{ field: 'vendorName', direction: 'Ascending'}] }
      },
      "vendors": {
        label: "Vendors", 
        arrayName: 'vendors',
        columns:[ {headerText: 'Vendor' ,template: this.vendorColTemplate, field:'vendorName', type:'string'}, {field: 'vendorWebsite', headerText: 'Website', type: 'string'}],
        sortSettings: {columns: [{ field: 'vendorName', direction: 'Ascending'}] }
      },
      "cookie": {
        label: "Cookie Requests", 
        arrayName: 'summarizedCookies',
        columns: [
          { field: "domain", headerText: "Domain", type: "string" },
          { field: "name", headerText: "Name", type: "string" },
          {
            field: "value",
            headerText: "Value",
            maxWidth: 300,
            minWidth: 100,
            width: 150,
            type: "string",
            allowResizing: true,
          },
          {
            field: "client_label",
            headerText: "Client Label",
            type: "string",
            autoFit: true,
          },
          {
            field: "predicted_label",
            headerText: "Predicted Label",
            type: "string",
            autoFit: true,
          },
          {
            field: "prediction_confidence",
            headerText: "Prediction Confidence",
            type: "number",
            autoFit: true,
          },
          {
            field: "severity_of_misclassification",
            headerText: "Severity Of Misclassification",
            type: "string",
            autoFit: true,
          },
        ],
        sortSettings: {columns: [{ field: 'domain', direction: 'Ascending'}] }
      }
    };
  }

  updateOpacity(args){
    console.log('Update Opacity', args);
    let updatedValue = args.value ? args.value : args;
    this.optInOpacity = (100 - updatedValue) / 100;
    this.optInOpacity = this.optInOpacity == 0.99 ? 1 : this.optInOpacity;
    this.optOutOpacity = 1.0 - this.optInOpacity;
    console.log(this.optInOpacity, this.optOutOpacity);
    this.inSvg.transition().duration(0).attr('opacity', this.optInOpacity);
    this.outSvg.transition().duration(0).attr('opacity', this.optOutOpacity);
    if ( this.optInOpacity == 1 ) {
      this.outSvg.selectAll(".nodeOut").attr("display", "none");
      this.inSvg.selectAll(".nodeIn").attr("display", "block");
    } else if ( this.optInOpacity == 0 ){
      this.outSvg.selectAll(".nodeOut").attr("display", "block");
      this.inSvg.selectAll(".nodeIn").attr("display", "none");
    } else {
      this.outSvg.selectAll(".nodeOut").attr("display", "block");
      this.inSvg.selectAll(".nodeIn").attr("display", "block");
    }
  }

  renderedTicks(args: SliderTickRenderedEventArgs) {
    console.log("xxxx", args);
    let li: any = args.ticksWrapper.getElementsByClassName('e-large');
    let remarks: any = ['Opt In', 'Opt Out'];
    for (let i: number = 0; i < li.length; ++i) {
        (li[i].querySelectorAll('.e-tick-both')[0] as HTMLElement).innerText = '';
        (li[i].querySelectorAll('.e-tick-both')[1] as HTMLElement).innerText = remarks[i];
    }    
  }

  dataBound(){
    this.grid.autoFitColumns();
  }

  scaleAndPan(): void {
    const $optInSvg:any = d3.select(`#${this.optInContainerId} svg`);
    const $optOutSvg:any = d3.select(`#${this.optOutContainerId} svg`);

    const optInBBox = $optInSvg.node().getBBox();
    const optInDivSize = $optInSvg.node().getBoundingClientRect();

    const optOutBBox = $optOutSvg.node().getBBox();
    const optOutDivSize = $optOutSvg.node().getBoundingClientRect();

    let optInWRatio = optInBBox.width / optInDivSize.width;
    let optInHRatio = optInBBox.height / optInDivSize.height;
    
    let optOutWRatio = optOutBBox.width / optOutDivSize.width;
    let optOutHRatio = optOutBBox.height / optOutDivSize.height;

    let scaleFactor = 1/((Math.max(optInWRatio, optInHRatio, optOutWRatio, optOutHRatio))+1);

    this.optInZoom.scaleTo(d3.select(`#${this.optInContainerId} svg`), scaleFactor);
    this.optInZoom.translateTo(d3.select(`#${this.optInContainerId} svg`), 0, optInBBox.height/2);
    
    $optInSvg.transition().duration(1000).attr('opacity', 1);
    $optOutSvg.transition().duration(1000).attr('opacity', 1);

    this.mainDuration = 750;
  }

  ngOnInit(): void {
    this.uuid = this.route.snapshot.queryParams['id'];

    let optInVizUrl = env.contentUrl.replace("{{uuid}}", this.uuid) + 'optInTreeViz.json',
        optInDataUrl = env.contentUrl.replace("{{uuid}}", this.uuid) + 'optInSummary.json',
        optOutVizUrl = env.contentUrl.replace("{{uuid}}", this.uuid) + 'optOutTreeViz.json',
        optOutDataUrl = env.contentUrl.replace("{{uuid}}", this.uuid) + 'optOutSummary.json',
        defaultSummaryUrl = env.contentUrl.replace("{{uuid}}", this.uuid) + 'defaultSummary.json',
        regionsUrl = env.apiUrl + "onlineRegions";

    forkJoin([
        this.http.get(optInVizUrl),
        this.http.get(optInDataUrl),
        this.http.get(optOutVizUrl),
        this.http.get(optOutDataUrl),
        this.http.get(defaultSummaryUrl).pipe(catchError(err => of(err))),
        this.http.get(regionsUrl).pipe(catchError(err => of(err)))
    ]).subscribe({      
      next:(results)=>{
        let optInVizResponse:any = results[0],
            optOutVizResponse:any = results[2];
 
        this.optInMaxGroupedNodes = Math.max(...optInVizResponse.nodes.map((node)=>{return node.groupedNodes != null ? node.groupedNodes : 0}));
        this.optOutMaxGroupedNodes = Math.max(...optOutVizResponse.nodes.map((node)=>{return node.groupedNodes != null ? node.groupedNodes : 0}));

        console.log("Max In", this.optInMaxGroupedNodes, "Max Out", this.optOutMaxGroupedNodes);
        
        this.optInData = results[1];
        this.optOutData = results[3],
        this.defaultData = results[4];
        this.hasTranscend = this.defaultData?.hasTranscend || this.optInData?.hasTranscend || this.optOutData?.hasTranscend;
        this.hasOneTrust = this.defaultData?.hasOneTrust || this.optInData?.hasOneTrust || this.optOutData?.hasOneTrust;
        this.hasCookieBot = this.defaultData?.hasCookieBot || this.optInData?.hasCookieBot || this.optOutData?.hasCookieBot;
        this.hasPrivici = this.defaultData?.hasPrivici || this.optInData?.hasPrivici || this.optOutData?.hasPrivici;
        this.hasKetch = this.defaultData?.hasKetch || this.optInData?.hasKetch || this.optOutData?.hasKetch;
        this.hasTrustArc = this.defaultData?.hasTrustArc || this.optInData?.hasTrustArc || this.optOutData?.hasTrustArc;
        this.hasTruyo = this.defaultData?.hasTruyo || this.optInData?.hasTruyo || this.optOutData?.hasTruyo;
        this.hasEvidon = this.defaultData?.hasEvidon || this.optInData?.hasEvidon || this.optOutData?.hasEvidon;
        this.hasCookieYes = this.defaultData?.hasCookieYes || this.optInData?.hasCookieYes || this.optOutData?.hasCookieYes;
        this.hasOsano = this.defaultData?.hasOsano || this.optInData?.hasOsano || this.optOutData?.hasOsano;
        this.hasTealium = this.defaultData?.hasTealium || this.optInData?.hasTealium || this.optOutData?.hasTealium;
        this.hasCustom = this.defaultData?.hasCustom || this.optInData?.hasCustom || this.optOutData?.hasCustom;
        this.customCMP = this.defaultData?.customCMP || this.optInData?.customCMP || this.optOutData?.customCMP;


        
        this.AVAILABLE_REGIONS = results[5];
        
        this.optInVendorTotal = Object.keys(this.optInData.vendors).length;
        this.optOutVendorTotal = Object.keys(this.optOutData.vendors).length;

        this.optOutNodes = this.treeVizToD3Tree(optOutVizResponse);
        this.optInNodes = this.treeVizToD3Tree(optInVizResponse);

        this.url = this.optInNodes.file;

        this.timestamp =  this.optOutData?.timestamp ? new Date(this.optOutData?.timestamp).toUTCString() : null;
        
        // backwards compat...
        this.region = this.optOutData?.event?.proxy?.location || DEFAULT_REGION;
        this.region = this.optOutData?.event?.region || this.region;
                
        if ( this.region.match(/[a-z]{2}\-.*\-\d{1}/) != null ){
          const allRegions = [...new Set([].concat(...this.AVAILABLE_REGIONS.map((o) => o.options)))];
          let foundRegion = allRegions.filter( r => r.value == this.region)
          if ( foundRegion && foundRegion.length == 1){
            this.region = foundRegion[0].label;
          }
        }

        this.render();

        setTimeout(() => {
          this.scaleAndPan();
          this.updateOpacity({value:1});
          this.propClick('total')
        }, 300);
      },
      error:(err)=>{
        console.log(err);
      },
      complete:()=>{
        console.log("Complete");
      }
    });
  }

  propClick(prop, inOrOut=0){
    console.log(prop, inOrOut);
    this.detailsHeader = `Opt ${inOrOut == 0 ? 'In' : 'Out'}`;
    this.detailsSubheader = `${this.detailsDict[prop].label}`;

    this.detailsData = inOrOut == 0 ? this.optInData : this.optOutData;
    this.detailsData = this.detailsData[this.detailsDict[prop].arrayName];
    if ( prop == 'vendors'){
      this.detailsData = Object.values(this.detailsData).map((item:any)=>{
        return {vendorName: item[0].vendorName, file: '', vendorWebsite: item[0].vendorWebsite};
      })
    } else {
      this.detailsData.sort((a,b)=>{return a.vendorName > b.vendorName});
    }
    
    this.selectedDetail = inOrOut + prop;
    
    this.grid.sortSettings = this.detailsDict[prop].sortSettings;
    this.grid.columns = this.detailsDict[prop].columns;
    // this.grid.refreshColumns();
  }

  treeVizToD3Tree(rawTreeViz){
    let treeViz = rawTreeViz;
    if ( treeViz instanceof String ){
      treeViz = JSON.parse(JSON.parse(rawTreeViz));  
    }
    let root = { 
      id: 0, 
      file: treeViz.nodes[0].file, 
      vendorName: 'Homepage',
      website: treeViz.nodes[0].vendorWebsite, 
      children:[], 
      isThirdParty: false,
      isDataRequest: treeViz.nodes[0].isDataRequest, 
      isJavaScriptRequest: treeViz.nodes[0].isJavaScriptRequest, 
      isCookieRequest: treeViz.nodes[0].isCookieRequest,
      weight: treeViz.nodes[0].groupedNodes ? treeViz.nodes[0].groupedNodes : 0
    };

    for(var link of treeViz.links) {
      let sourceIndex = link.source;
      let targetIndex = link.target;

      let sourceObj = {id: link.source, file: treeViz.nodes[sourceIndex].file, vendorName: treeViz.nodes[sourceIndex].vendorName, website: treeViz.nodes[sourceIndex].vendorWebsite, children:[], isThirdParty: treeViz.nodes[sourceIndex].isThirdParty, isDataRequest: treeViz.nodes[sourceIndex].isDataRequest, isJavaScriptRequest: treeViz.nodes[sourceIndex].isJavaScriptRequest, isCookieRequest: treeViz.nodes[sourceIndex].isCookieRequest, weight: treeViz.nodes[sourceIndex].groupedNodes ? treeViz.nodes[sourceIndex].groupedNodes : 0 };
      let targetObj = {id: link.target, file: treeViz.nodes[targetIndex].file, vendorName: treeViz.nodes[targetIndex].vendorName, website: treeViz.nodes[targetIndex].vendorWebsite, children:[], isThirdParty: treeViz.nodes[targetIndex].isThirdParty, isDataRequest: treeViz.nodes[targetIndex].isDataRequest, isJavaScriptRequest: treeViz.nodes[targetIndex].isJavaScriptRequest, isCookieRequest: treeViz.nodes[targetIndex].isCookieRequest, weight: treeViz.nodes[targetIndex].groupedNodes ? treeViz.nodes[targetIndex].groupedNodes : 0};
    
      let node = findNode(root, sourceObj)
      if ( node ){
        node.children.push(targetObj);
      }	
    }
  
    function findNode(startNode, nodeToFind) {
      if (!startNode) return null;
      if ( startNode.id == nodeToFind.id) return startNode;
      for(var child of startNode.children){
        let foundNode = findNode(child, nodeToFind);
        if ( foundNode ){
          return foundNode;
        }
      }
    }

    return root;
  }

  render(): void {
    let that = this;

    var i = 0;
    var w = $(`#${this.optInContainerId}`).width();
    var h = $(`#${this.optInContainerId}`).height();

    var margin = { top: 0, right: 0, bottom: 0, left: 0 };
    var width = w;
    var height = h;

    this.optInZoom = d3.zoom()
      .scaleExtent([0.01, 3])
      .on("zoom", function (event) {
        optInSvg.attr("transform", event.transform)
        optOutSvg.attr("transform", event.transform)
      })      
      ;

    var optInSvg = this.inSvg = d3.select(`#${this.optInContainerId}`).append("svg")
    .attr("width", width + margin.right + margin.left)
    .attr("height", height + margin.top + margin.bottom)
    .attr('opacity', 0)
    .attr('class', 'inG')
    .append("g");

    var optOutSvg = this.outSvg = d3.select(`#${this.optOutContainerId} svg`)
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)
      .attr('opacity', 0)
      .attr('class', 'outG')
      .append("g");

    d3.select(`#${this.optInContainerId} svg`)
      .call(this.optInZoom)
      .on("dblclick.zoom", (event) => {
        console.log("xxx");
        this.scaleAndPan();
      });
    // d3.select(`#${this.optOutContainerId} svg`).call(this.optOutZoom);
    
    var optInTree = d3.tree().size([height, width]).nodeSize([50, 0]).separation((a,b)=>{return 1;});// (a.parent == b.parent ? 1 : 2) / a.depth;})
    var optOutTree = d3.tree().size([height, width]).nodeSize([50, 0]).separation((a,b)=>{return 1;});// (a.parent == b.parent ? 1 : 2) / a.depth;});

    setup(optInSvg, this.optInNodes, optInTree, 'In', this.optInMaxGroupedNodes);
    setup(optOutSvg, this.optOutNodes, optOutTree, 'Out', this.optOutMaxGroupedNodes);

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px");

    function setup(targetSvg, data, tree, className, maxWeight, rootScope=that) {

      let root:any = d3.hierarchy(data, function (d: any) { return d.children; });
  
      root.x0 = height / 2;
      root.y0 = 0;
  
      update(root, rootScope.mainDuration);
  
      function update(source, duration=0) {
        console.log("duration", duration);
        console.log('update running');
        // ASSIGN THE X AND Y POSITION FOR THE NODES:
        var treeData = tree(root)
        console.log(treeData);
  
        // COMPUTE THE NEW TREE LAYOUT:
        var nodes = treeData.descendants(),
          links = treeData.descendants().slice(1);
  
        // NORMALIZE FOR FIXED-DEPTH:
        nodes.forEach(function (d: any) { d.y = d.depth * 180 });
  
        // *************** NODES SECTION ***************
  
        // UPDATE THE NODES:
        var node = targetSvg.selectAll('g.node')
          .data(nodes, function (d: any) { return d.id || (d.id = ++i); });
  
        // ENTER ANY NEW MODES AT THE PARENT'S PREVIOUS POSITION:
        var nodeEnter: any = node.enter().append('g')
          .attr('class', 'node' + className)
          .attr('node-name', (d: any) => d.data.name)
          .attr("transform", function (d: any) {
            return "translate(" + source.x0 + "," + source.y0 + ")";
          })
          .on('click', click)
          .on("mouseover", function(event,d) {
            if ( that.optInOpacity == 1 || that.optOutOpacity == 1){
              div.transition()
              .duration(200)
              .style("opacity", 1);
  
              let vendorName = d.data.vendorName == 'Unknown' && d.data.isThirdParty == false ? 'First Party' : d.data.vendorName;
              let ttHtml = `<b>${vendorName}</b><br/>`;
              if ( d.data.weight > 0 ){
                ttHtml += `Request Count: ${d.data.weight}`;
              } else {

                const yardstick = new RegExp(`.{50}`, 'g');
                const pieces = d.data.file.match(yardstick);

                if ( pieces && pieces.length > 1 ){
                  ttHtml += `${pieces.join("<br/>")}`;
                } else {
                  ttHtml += d.data.file;
                }
              }
              
              div.html(`${ttHtml}`)
                .style("left", (event.pageX + 28) + "px")
                .style("top", (event.pageY - 28) + "px");
            }
        })
        .on("mouseout", function(d) {
          div.transition()
            .duration(500)
            .style("opacity", 0);
        });
       
        nodeEnter.append('circle')
          .attr('r', (d:any) => {
            let r = 16;

            if (d.data.vendorName == 'Homepage') return '48px';
            
            if ( d.data.weight > 0 ) {
              console.log("weight", d.data.weight);
               r = Math.ceil(((d.data.weight / maxWeight) * 2)) * 16;
               console.log(r);
            }
            
            return r + "px";
          })
          .attr('stroke-width', 2)
          .attr('stroke', (d:any)=>{
              if (d.data.isThirdParty == true){
                return "#C1334A";
              }
              return "#60A561";
          })
          .attr('fill', 'gray')
  
  
        nodeEnter.append('image')
          .attr('xlink:href', (d: any) => {
            return `${env.iconBaseUrl}${d.data.website ? d.data.website : 'unknown'}.ico`;
          })
          .attr("x", "-8px")
          .attr("y", "-8px")
          .attr('width', (d:any)=>{
              return 16;
          })
          .attr('height', (d:any)=>{            
            return 16;
          })          
          .attr('cursor', (d:any)=>{
            if ( d.data.children && d.data.children.length > 0){
              return 'pointer';
            }
            return '';
          });

        // UPDATE:
        var nodeUpdate = nodeEnter.merge(node);
  
        // TRANSITION TO THE PROPER POSITION FOR THE NODE:
        nodeUpdate.transition()
          .duration(duration)
          .attr("transform", function (d: any) {
            return "translate(" + d.x + "," + d.y + ")";
          });
  
        // UPDATE THE NODE ATTRIBUTES AND STYLE:
        // nodeUpdate.select('circle.node')
          // .attr('r', 10)
          // .style("fill", function (d: any) {
          //   return d._children ? "lightsteelblue" : "#fff";
          // })
        //   .attr('cursor', 'pointer');
  
        // REMOVE ANY EXITING NODES:
        var nodeExit = node.exit().transition()
          .duration(duration)
          .attr("transform", function (d: any) {
            return "translate(" + source.x + "," + source.y + ")";
          })
          .remove();

        // *************** LINKS SECTION ***************
  
        // UPDATE THE LINKS:
        var link = targetSvg.selectAll('path.link')
          .data(links, function (d: any) { return d.id; });
  
        // ENTER ANY NEW LINKS AT THE PARENT'S PREVIOUS POSITION:
        var linkEnter: any = link.enter().insert('path', "g")
          .attr("class", "link")
          .attr('d', function (d: any) {
            var o = { x: source.x0, y: source.y0 }
            return diagonal(o, o)
          });
  
        // UPDATE:
        var linkUpdate = linkEnter.merge(link);
  
        // TRANSITION BACK TO THE PARENT ELEMENT POSITION:
        linkUpdate.transition()
          .duration(duration)
          .attr('d', function (d: any) { return diagonal(d, d.parent) });
  
        // REMOVE ANY EXITING LINKS:
        var linkExit = link.exit().transition()
          .duration(duration)
          .attr('d', function (d: any) {
            var o = { x: source.x, y: source.y }
            return diagonal(o, o)
          })
          .remove();
  
        // STORE THE OLD POSITIONS FOR TRANSITION:
        nodes.forEach(function (d: any) {
          d.x0 = d.x;
          d.y0 = d.y;
        });
  
        // CREATE A CURVED (DIAGONAL) PATH FROM PARENT TO THE CHILD NODES:
        function diagonal(s, d) {
  
          let path = `M ${s.x} ${s.y} C ${(s.x + d.x) / 2} ${s.y}, ${(s.x + d.x) / 2} ${d.y},${d.x} ${d.y}`
  
          return path
        }
  
        // ----------------------------------------
        // TOGGLE CHILDREN ON CLICK:
        function click(event, d) {
          that.clipboard.copyFromContent(d.data.file);
        }
        
      }
    };
  }

  changeType(){
    if ( this.selectedType == "Opt In") {
      this.updateOpacity(100)
      this.selectedType = "Opt Out";
    } else {
      this.updateOpacity(1)
      this.selectedType = "Opt In";
    }
  }
}
