const json_data: string =
    `
[
    {
        "all": {
          "block": {
            "regexList": [
              ".*js.datadome.co.*", ".*eep.io.*"
            ]
          }	
        },
        "default": {},
        "optIn": {
            "gpc": true,
            "onetrust": true,
            "others": true
        },
        "optOut": {
            "gpc": true,
            "onetrust": true,
            "others": true
        }
    }
]
`;

export { json_data };
