import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxTypedJsComponent } from 'ngx-typed-js';
import { environment as env } from "../../../environments/environment";
import { json_data } from './json-template';

@Component({
  selector: 'hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => active', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ],
})
export class HeroComponent implements OnInit {

  @ViewChild("typedText")
  public typedText: NgxTypedJsComponent;

  @ViewChild("scanForm")
  public form: NgForm;

  visible = true;
  submitted = false;
  buttonDisabled = false;
  buttonText = "Submit";
  json = json_data;

  isGlobal = false;

  url = "";
  email = "";
  region = "";
  regionDisabled = false;
  regionOnline = false;

  regionOptions = [];

  gpcEnabled = false;

  provider;

  constructor(
    private http: HttpClient,
    private router: Router) {

    if (location.hostname.startsWith("global") == true || location.hostname.startsWith("dev-global") == true) {
      this.isGlobal = true;
    }
  }

  ngOnInit(): void {
    this.http.get(env.apiUrl + "onlineRegions").subscribe({
      next: (response: any) => {
        // console.log(response);
        this.regionOptions = response
      },
      error: (err) => {
        console.log(err);

      }
    });

    this.provider = "onetrust";
    if (location.href.includes("ensighten")) {
      this.provider = "ensighten";
    } else if (location.href.includes("gpc")) {
      this.provider = "gpc";
    }

  }

  onSubmit() {
    // console.log(this.form.value);
    // return;
    console.log(this.url + " " + this.email + " " + this.region + " regionDisabled: " + this.regionDisabled + " regionOnline: " + this.regionOnline);
    this.typedText.stop();
    this.form.form.disable();
    if (this.url && this.email) {
      // this.buttonDisabled = true;

      let provider = "onetrust";
      if (location.href.includes("ensighten")) {
        provider = "ensighten";
      } else if (location.href.includes("gpc")) {
        provider = "gpc";
      }

      if (this.form.value.gpcEnabled == true) {
        this.json = JSON.stringify({
          GPCOptOut: true
        })
      }

      //defining data
      let data = { url: this.url, email: this.email, provider: provider };

      //if json template matches config before submit, do not include config
      if (json_data !== this.json) {
        data['config'] = this.json;
      }

      if (this.isGlobal) {
        data["region"] = this.region;
        data["regionDisabled"] = this.regionDisabled;
        data["regionOnline"] = this.regionOnline == true;
      }
      console.log(data);

      this.http.post(env.apiUrl + "scan", data).subscribe({
        next: (response: any) => {
          console.log(response);
          this.visible = false;
          this.submitted = true;
        },
        error: (err) => {
          console.log(err);
        }
      });
    }
  }

  onSelectRegion(event) {
    console.log(event);
    this.region = event.options[0].value;
    this.regionDisabled = event.options[0].disabled;
    this.regionOnline = event.options[0].online;
  }

  updateJSONchange(event) {
    this.json = event;
    console.log(this.json);
  }
}

