import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroComponent } from './components/hero/hero.component';
import { FooterComponent } from './components/footer/footer.component';
import { ScanComponent } from './components/scan/scan.component';
import { FormsModule } from '@angular/forms';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { CustomFormsModule } from 'ng2-validation';
import { ReportComponent } from './components/report/report.component';
import { GridModule, ResizeService } from '@syncfusion/ej2-angular-grids';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { SliderModule } from '@syncfusion/ej2-angular-inputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlValidator } from './validators/urlValidator.validator';
import { Select2Module } from 'ng-select2-component';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { RadioButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';


@NgModule({
  declarations: [
    AppComponent,
    HeroComponent,
    FooterComponent,
    ScanComponent,
    ReportComponent,
    UrlValidator
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgxTypedJsModule,
    FormsModule,
    CustomFormsModule,
    GridModule,
    SliderModule,
    BrowserAnimationsModule,
    Select2Module,
    AccordionModule,
    RadioButtonModule,
    CheckBoxModule
  ],
  providers:[
    PageService,
    SortService,
    FilterService,
    GroupService,
    ResizeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
