<div class="pt-lg-10 pt-5 footer bg-white">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="mb-4"><a href="https://www.vaultjs.com"><img src="assets/img/vault-logo-footer.png" alt="" class=""></a>
                    <div class="mt-4">
                        <p>Vault JS monitors your websites to detect malicious code, PII leakage and more.</p>
                        <div class="fs-4 mt-4">
                            <a class="mdi mdi-facebook text-muted me-2" href="https://www.linkedin.com/company/vault-js" target="_blank">
                                <i data-feather="linkedin"></i>
                            </a>
                            <a class="mdi mdi-twitter text-muted me-2" href="https://twitter.com/Vault_JS" target="_blank">
                                <i data-feather="twitter"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 offset-lg-3">
                <div class="mb-4">
                    <h3 class="fw-bold mb-3">Company</h3>
                    <ul class="nav nav-footer flex-column nav-x-0 list-unstyled">
                        <li class=" "><a class="nav-link" href="https://www.vaultjs.com/about-us">About</a></li>
                        <li class=" "><a class="nav-link" href="https://www.vaultjs.com/landing-page-free-report">Free Report</a></li>
                        <li class=" "><a class="nav-link" href="https://www.vaultjs.com/insights">Blog</a></li>
                        <li class=" "><a class="nav-link" href="https://www.vaultjs.com/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="mb-4">
                    <h3 class="fw-bold mb-3">Get in touch</h3>                    
                    <p class="mb-1">Email: <a href="mailto:consent@vaultjs.com">consent@vaultjs.com</a></p>
                    <p>Phone: <span class="text-dark fw-semi-bold">(650) 241-9510</span></p>
                </div>
            </div>
        </div>
        <div class="align-items-center g-0 border-top py-2 mt-6 row">
            <div class="col-lg-4 col-md-5 col-sm-12"><span>© 2023 Vault JS All Rights Reserved</span></div>
            <div class="d-md-flex justify-content-end col-lg-8 col-md-7 col-sm-12">
                <nav class="nav nav-footer">
                    <a class="nav-link ps-0" href="https://www.vaultjs.com/privacy" target="_blank">Privacy Policy</a>
                </nav>                    
            </div>
        </div>
    </div>
</div>