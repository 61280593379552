<div class="py-lg-10 py-10 bg-auto"
    style="background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), rgba(75, 158, 213, 0.3);">
    <div class="justify-content-center row">
        <div class="col">
<div class="text-center">
    <h1 class="display-4 fw-bold">Summary</h1>
    <p  *ngIf="timestamp" class="px-8">Generated {{timestamp}}{{region ? " from " + region : ""}}</p>
    <p class="lead px-8">Please review the results for "{{url}}" below. If you have any questions please <a href="mailto:consent@vaultjs.com">contact us</a>.</p>
    <p *ngIf="hasTranscend == true" class="lead px-8">Transcend: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p>
    <p *ngIf="hasOneTrust == true" class="lead px-8">OneTrust: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p>
    <p *ngIf="hasCookieBot == true" class="lead px-8">UserCentrics: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p>
    <p *ngIf="hasPrivici == true" class="lead px-8">Privici: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p>
    <p *ngIf="hasKetch == true" class="lead px-8">Ketch: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p>
    <p *ngIf="hasTrustArc == true" class="lead px-8">TrustArc: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p>
    <p *ngIf="hasTruyo == true" class="lead px-8">Truyo: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p>
    <p *ngIf="hasEvidon == true" class="lead px-8">Evidon: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p> 
    <p *ngIf="hasCookieYes == true" class="lead px-8">CookieYes: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p>
    <p *ngIf="hasOsano == true" class="lead px-8">Osano: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p>
    <p *ngIf="hasTealium == true" class="lead px-8">Tealium: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p> 
    <p *ngIf="hasCustom == true" class="lead px-8">{{customCMP}}: <span style="color: green; font-weight: bold;">DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
    </p>
    <p *ngIf="hasTranscend == false && hasOneTrust == false && hasCookieBot == false && hasPrivici== false && hasKetch== false && hasTrustArc == false && hasTruyo == false && hasEvidon == false && hasCookieYes == false && hasOsano == false && hasTealium == false && hasCustom == false"  class="lead px-8">Consent Tool: <span style="color: red; font-weight: bold;"> NOT DETECTED </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
    </p>
     
        
    <p class="">Zoom in/out using the scroll wheel on your mouse, or pinch in/out on your trackpad. You can also drag to pan around.</p>
</div>
        </div>
        <div class="container">
            <div class="justify-content-center row">
                <div class="col-lg-9 col-md-12 col-sm-12">
                    <div class="row text-center gx-20">
                        <div class="col">                            
                            <div id="optInRequestGraphContainer" style="width: 100%; height: 375px; border-style: solid; border-color: black; border-width: 1px; background-color: white;"></div>
                        </div>
                    </div>
                    <div class="mt-10">
                        <div class="row text-center gx-20">
                            <div class="col">
                                <!-- <ejs-slider width="50%" id='defaultslidertooltip' #slider [value]='value' min="0" max="100" [type]='type' [ticks]='slider_ticks'  [showButtons]='showButtons' [tooltip]="tooltip" (renderedTicks)="renderedTicks($event)" (valueChange)="updateOpacity($event);"></ejs-slider> -->
                                <!-- <div role="group" class="btn-group">
                                    <input class="btn-check" name="options" type="radio" autocomplete="off" id="tbg-radio-1" value="1" checked="">
                                    <label tabindex="0" for="tbg-radio-1" class="btn btn-outline-primary">Opt In</label>
                                    <input class="btn-check" name="options" type="radio" autocomplete="off" id="tbg-radio-2" value="2">
                                    <label tabindex="0" for="tbg-radio-2" class="btn btn-outline-primary">Opt Out</label>
                                </div> -->
                                <div role="group" class="mb-2 me-1 btn-group">
                                    <input class="btn-check" type="checkbox" autocomplete="off" id="tbg-check-1" value="1" (click)="changeType()">
                                    <label tabindex="0" for="tbg-check-1" class="btn btn-primary">{{selectedType}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-10">
                        <div class="row text-center gx-20">
                            <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12">
                                <div class="mb-s shadow card">
                                    <div class="card-body">
                                        <div class="text-center">
                                            <h3 class="mb-1" [class.selectedType]="optInOpacity == 1">Opt In</h3>
                                        </div>
                                        <div class="d-flex justify-content-between border-bottom py-2 fs-6">
                                            <span class="vaultLink" [class.vaultSelected]="selectedDetail == '0total'" (click)="propClick('total')">All Requests</span>
                                            <span>{{optInData?.tpRequests.length}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between border-bottom py-2 fs-6">
                                            <span class="vaultLink" [class.vaultSelected]="selectedDetail == '0js'" (click)="propClick('js')">Javascript Requests</span>
                                            <span class="">{{optInData?.tpJavaScriptRequests.length}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between border-bottom py-2 fs-6">                                        
                                            <span class="vaultLink" [class.vaultSelected]="selectedDetail == '0data'" (click)="propClick('data')">Data Requests</span>
                                            <span class="">{{optInData?.tpDataRequests.length}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between border-bottom py-2 fs-6">
                                            <span class="vaultLink" [class.vaultSelected]="selectedDetail == '0cookie'" (click)="propClick('cookie')">Cookies</span>
                                            <span class="">{{optInData?.summarizedCookies.length}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between py-2 fs-6">
                                            <span class="vaultLink" [class.vaultSelected]="selectedDetail == '0vendors'" (click)="propClick('vendors')">Vendors</span>
                                            <span class="">{{optInVendorTotal}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-4 col-md-6 col-sm-12">
                                <div class="mb-s shadow card">
                                    <div class="card-body">
                                        <div class="text-center">
                                            <h3 class="mb-1" [class.selectedType]="optOutOpacity == 1">Opt Out & GPC Enabled</h3>
                                        </div>
                                        <div class="d-flex justify-content-between border-bottom py-2 fs-6">
                                            <span class="vaultLink" [class.vaultSelected]="selectedDetail == '1total'" (click)="propClick('total', 1)">All Requests</span>
                                            <span>{{optOutData?.tpRequests.length}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between border-bottom py-2 fs-6">
                                            <span class="vaultLink" [class.vaultSelected]="selectedDetail == '1js'" (click)="propClick('js', 1)">Javascript Requests</span>
                                            <span class="">{{optOutData?.tpJavaScriptRequests.length}}</span>
                                        </div>                                        
                                        <div class="d-flex justify-content-between border-bottom py-2 fs-6">
                                            <span class="vaultLink" [class.vaultSelected]="selectedDetail == '1data'" (click)="propClick('data', 1)">Data Requests</span>
                                            <span class="">{{optOutData?.tpDataRequests.length}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between border-bottom py-2 fs-6 cookieBorder">
                                            <span class="vaultLink" [class.vaultSelected]="selectedDetail == '1cookie'" (click)="propClick('cookie', 1)">Cookies</span>
                                            <span class="">{{optOutData?.summarizedCookies.length}}</span>
                                        </div>
                                        <div class="d-flex justify-content-between py-2 fs-6">
                                            <span class="vaultLink" [class.vaultSelected]="selectedDetail == '1vendors'" (click)="propClick('vendors', 1)">Vendors</span>
                                            <span class="">{{optOutVendorTotal}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-10">
                        <div class="row text-center gx-20">
                            <div class="col">
                                <div class="mb-s shadow card">
                                    <div class="card-body">
                                        <div class="text-center">
                                            <h4 class="mb-1">{{detailsHeader}}</h4>
                                            <h5 class="mb-1">{{detailsSubheader}}</h5>
                                        </div>
                                        <div class="text-start">
                                            <ejs-grid #grid 
                                            height="400px"
                                            [dataSource]="detailsData"
                                            locale='en-US' 
                                            [allowPaging]="true" 
                                            [pageSettings]="pageSettings" 
                                            [allowSorting]="true" 
                                            [allowSelection]="false"
                                            [allowFiltering]="true"
                                            [filterSettings]="filterOptions"
                                            [enableHover]="false"
                                            [toolbar]="toolbar"
                                            [allowResizing]="true"
                                            (dataBound)='dataBound()' 
                                            ></ejs-grid>
                                            <ng-template #template let-data>
                                                <img style="width: 32px; height:32px;margin-right: 5px;" src="{{iconBaseUrl}}{{ data.vendorWebsite ? data.vendorWebsite.toLowerCase() : 'unknown' }}.ico"/>{{data.vendorName}}
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>
</div>
<footer></footer>