import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl } from '@angular/forms';

function validateUrlFactory() {
  return (c: FormControl) => {    
    try {
        let allegedUrl = c.value;
        // let urlRegex = new RegExp('https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)', "g");
        // let isMatch = urlRegex.test(allegedUrl);
        // console.log(isMatch);
        // return isMatch ? null : {url : true};
        console.log(allegedUrl);
        if (allegedUrl.indexOf('://') === -1) {
            return { missingProtocol: true };
        }
        const url = new URL(allegedUrl);
        console.log(url);
        return null;
      } catch (_) {
        return { url: true };
      }
  };
}

@Directive({
  selector: '[validateUrl][ngModel],[validateUrl][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => UrlValidator), multi: true }
  ]
})
export class UrlValidator {

  validator: Function;

  constructor() {
    this.validator = validateUrlFactory();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }
}