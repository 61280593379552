<div class="py-lg-18 py-10 bg-auto" style="background: url('/assets/img/hero-graphic.png'), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), rgba(75, 158, 213, 0.3);
        background-repeat: 'no-repeat';
        background-size: 'cover';
        background-position: 'top center';">
    <!-- <div class="container" [ngClass]="{'fade-out': !visible}" > -->
    <div class="container" *ngIf="!submitted">
        <div class="justify-content-center row">
            <div class="col-xl-7 col-lg-7 col-md-12">
                <div class="py-8 py-lg-0 text-center" style="height: 374px;">
                    <h1 class="display-2 fw-bold mb-3 text-primary">
                        <ngx-typed-js #typedText [strings]="['Privacy', 'Security', 'Data', 'Consent']" [typeSpeed]="70"
                            [backSpeed]="50" [loop]="false">
                            <span class="text-dark px-3 px-md-0">Validate </span> <span class="typing"></span>
                        </ngx-typed-js>
                    </h1>
                    <p class="mb-6 h2 text-dark">Please enter a URL and your email address. We'll notify you once the
                        summary is available.</p>
                    <div class="row px-md-40">
                        <form #scanForm="ngForm" (ngSubmit)="scanForm.form.valid && onSubmit()">
                            <div class="mb-3 col ps-0 ms-2 ms-md-0">
                                <input placeholder="URL" id="url" name="url" type="text" class="form-control"
                                    [(ngModel)]="url" required validateUrl #txtUrl="ngModel"
                                    [ngClass]="{ 'is-invalid': txtUrl.invalid && (txtUrl.dirty || txtUrl.touched), 'is-valid': !txtUrl.invalid }">
                                <div class="invalid-feedback" *ngIf="txtUrl.errors?.['required']">Please enter a URL
                                </div>
                                <div class="invalid-feedback" *ngIf="txtUrl.errors?.['url']">Please enter a valid URL
                                </div>
                                <div class="invalid-feedback" *ngIf="txtUrl.errors?.['missingProtocol']">Please specify
                                    the protocol (ie http or https)</div>
                            </div>
                            <div class="mb-3 col ps-0 ms-2 ms-md-0">
                                <input placeholder="Email Address" id="email" name="email" type="text"
                                    class="form-control" [(ngModel)]="email" required email #txtEmail="ngModel"
                                    [ngClass]="{ 'is-invalid': txtEmail.invalid && (txtEmail.dirty || txtEmail.touched), 'is-valid': !txtEmail.invalid }">
                                <div class="invalid-feedback" *ngIf="txtEmail.errors?.['required']">Please enter your
                                    email address</div>
                                <div class="invalid-feedback" *ngIf="txtEmail.errors?.['email']">Please enter a valid
                                    email address</div>
                            </div>
                            <div *ngIf="isGlobal" class="mb-3 col ps-0 ms-2 ms-md-0">
                                <select2 class="form-control" [data]="regionOptions" (update)="onSelectRegion($event)"
                                    [styleMode]="material" placeholder="Region" overlay="true"></select2>
                            </div>
                            <div *ngIf="isGlobal && provider == 'gpc'" class="mb-3 col ps-0 ms-2 ms-md-0">
                                <ejs-accordion>
                                    <e-accordionitems>
                                        <e-accordionitem>
                                            <ng-template #header>
                                                <div style="color: #999; font-size: 14px; !important">GPC</div>
                                            </ng-template>
                                            <ng-template #content>
                                                <div class="settingsItem">
                                                    <div class="row">
                                                        <ejs-checkbox name="gpcEnabled" label="Opt-Out Enabled" [(checked)]="gpcEnabled" [(ngModel)]="gpc"></ejs-checkbox>
                                                    </div>
                                                    <!-- <div class="row">
                                                        <ejs-radiobutton label="Opt-In" name="gpc" value="optIn" checked="true" [disabled]="!gpcEnabled" [(ngModel)]="gpc"></ejs-radiobutton>
                                                    </div>
                                                    <div class="row">
                                                        <ejs-radiobutton label="Opt-Out" name="gpc" value="optOut" [disabled]="!gpcEnabled" [(ngModel)]="gpc"></ejs-radiobutton>
                                                    </div> -->
                                                </div>
                                            </ng-template>
                                        </e-accordionitem>
                                        <!-- <e-accordionitem>
                                            <ng-template #header>
                                                <div style="color: #999; font-size: 14px; !important">OneTrust</div>
                                            </ng-template>
                                            <ng-template #content>
                                                <div class="settingsItem">
                                                    <div class="row">
                                                        <ejs-radiobutton label="Default" name="oneTrust" value="default" checked="true"></ejs-radiobutton>
                                                    </div>
                                                    <div class="row">
                                                        <ejs-radiobutton label="Opt-In" name="oneTrust" value="optIn" checked="false"></ejs-radiobutton>
                                                    </div>
                                                    <div class="row">
                                                        <ejs-radiobutton label="Opt-Out" name="oneTrust" value="optOut" checked="false"></ejs-radiobutton>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </e-accordionitem>
                                        <e-accordionitem>
                                            <ng-template #header>
                                                <div style="color: #999; font-size: 14px; !important">Ensighten</div>
                                            </ng-template>
                                            <ng-template #content>
                                                <div class="settingsItem">
                                                    <div class="row">
                                                        <ejs-radiobutton label="Default" name="ensighten" value="default" checked="true"></ejs-radiobutton>
                                                    </div>
                                                    <div class="row">
                                                        <ejs-radiobutton label="Opt-In" name="ensighten" value="optIn" checked="false"></ejs-radiobutton>
                                                    </div>
                                                    <div class="row">
                                                        <ejs-radiobutton label="Opt-Out" name="ensighten" value="optOut" checked="false"></ejs-radiobutton>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </e-accordionitem> -->
                                    </e-accordionitems>
                                </ejs-accordion>
                            </div>
                            <div></div>

                            <!--New JSON Feature-->
                            <ejs-accordion *ngIf="provider == 'onetrust'">
                                <e-accordionitems>
                                    <e-accordionitem>
                                        <ng-template #header>
                                            <div style="color: #999; font-size: 14px; !important">Config (Optional)
                                            </div>
                                        </ng-template>
                                        <ng-template #content>
                                            <div>
                                                <textarea placeholder={{json}} [ngModel]="json"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (ngModelChange)="updateJSONchange($event)" rows="10"
                                                    class="w-100"></textarea>
                                            </div>
                                        </ng-template>
                                    </e-accordionitem>
                                </e-accordionitems>
                            </ejs-accordion>



                            <div class="mb-3 col-auto ps-0 py-4">
                                <button type="submit" class="btn btn-primary" [disabled]="!scanForm.valid">
                                    <span *ngIf="buttonDisabled" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span> {{buttonText}}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container" *ngIf="submitted" [ngClass]="{'fade-in': submitted}" > -->
    <div class="container" *ngIf="submitted">
        <div class="justify-content-center row">
            <div class="col-xl-7 col-lg-7 col-md-12">
                <div class="py-8 py-lg-0 text-center" style="height: 374px;">
                    <h1 class="display-2 fw-bold mb-3 text-primary">
                        <span class="px-3 px-md-0" style="color: #5988C4;">Thank You!</span>
                    </h1>
                    <p class="mb-6 h2">You will receive an e-mail with the link once the report is ready.</p>
                </div>
            </div>
        </div>
    </div>
</div>